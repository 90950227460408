import axios from "axios";

import { URLS } from "../constants/urls";

const client = axios.create({
  baseURL: URLS.baseURL,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = "application/json";

    return config;
  },
  (error) => error
);

export default client;
