import img from '../../img/sarthak.png';
import himal from '../../img/himal.png';
// import shailesh from '../../img/shailesh.png';
import Carousel from 'react-bootstrap/Carousel';

const Testinomial = () => {

    return (
      <div id='testinomail'>
        <div className='container p-5'>
          <h2 className='text-center pb-4 primary'>Testinomial</h2>
          <h3 className='text-center'>See what our clients has to say.</h3>
          <Carousel variant="dark">
          <Carousel.Item>
              <div className='row m-0 py-4'>
                <div className='col-md-6 col-sm-12 flex-center'>
                  <img
                    className="d-block c-img"
                    src={img}
                    alt="First slide"
                  />
                </div>
                <div className='col-md-6 col-sm-12 flex-center'>
                  <div className='carousel-cap'>
                    <h5 className=''>ABC DEF</h5>
                    <p className=''>CEO, ABC</p>
                    <p className='fw-normal pr-3'>
                    As the CEO of 4digitech, I am thrilled to recommend 4DigiTech as a reliable and innovative technology partner.
                     The team at 4DigiTech was extremely professional, knowledgeable and dedicated to ensuring our success. 
                     We highly recommend 4DigiTech to any company looking to drive innovation through technology.
                     </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row m-0 py-4'>
                <div className='col-md-6 col-sm-12 flex-center'>
                  <img
                    className="d-block c-img"
                    src={himal}
                    alt="First slide"
                  />
                </div>
                <div className='col-md-6 col-sm-12 flex-center'>
                  <div className='carousel-cap'>
                    <h5 className=''>Himal Gautam</h5>
                    <p className=''>CEO, Platinum Educational Consultancy</p>
                    <p className='fw-normal pr-3'>
                    As the CEO of 4digitech, I am thrilled to recommend 4DigiTech as a reliable and innovative technology partner.
                     The team at 4DigiTech was extremely professional, knowledgeable and dedicated to ensuring our success. 
                     We highly recommend 4DigiTech to any company looking to drive innovation through technology.
                     </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  };
  
  export default Testinomial;
  