import logo from '../../img/logo.png'
const Navbar = () => {
  return (
    <div className='nb'>
      <div className="container p-0">
        <nav className="navbar navbar-expand-md navbar-light bg-light py-3">
          <a className="navbar-brand" href="/#home">
            <img src={logo} alt="4digitech logo" height="60" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse flex-row-reverse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item px-2 fw-bold active">
                <a className="nav-link" href="/#about">About <span className="sr-only"></span></a>
              </li>
              <li className="nav-item px-2 fw-bold">
                <a className="nav-link" href="/#services">Services</a>
              </li>
              <li className="nav-item px-2 fw-bold">
                <a className="nav-link" href="/#portfolio">Portfolio</a>
              </li>
              <li className="nav-item px-2 fw-bold">
                <a className="nav-link" href="/#carrer">Carrer</a>
              </li>
              <li className="nav-item px-2 fw-bold">
              <a className="btn-right-logo" href="/#contact">
                  Contact Us
                  <div className="arrow-wrapper">
                      <div className="arrow"></div>
                  </div>
              </a>
                {/* <a className="nav-link c-btn" href="/#contact">Contact Us <ArrowForwardIosTwoToneIcon fontSize='small'></ArrowForwardIosTwoToneIcon></a> */}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
