
import sujal from '../img/sujal.jpg';
import sarthak from '../img/sarthak.jpg';
import regan from '../img/regan.jpg';
import jenish from '../img/jenish.jpg';
import abhishek from '../img/abhishek.jpg';
import dipu from '../img/dipu.jpg';
import raman from '../img/raman.jpg';
import kushal from '../img/kushal.jpg';
import Navbar from '../components/Navbar';
import HowWeWork from "../components/HowWeWork";
import Footer from "../components/Footer/index";
import Subscribe from "../components/Subscribe";
import ContactUs from "../components/ContactUs";

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
const About = () => {
  return (
    <>
    <Navbar />
    <div className="about">
      <div className="container">
        <div className="row pt-7 h-700">
        <div className='ol-fade-bottom'></div>
          <div className="col-md-6 col-sm-12 pt-5">
              <h1 className='primary'>About Us</h1>
              <p className='headline'>A small creative team of artists and developers contributing to a better world. </p>
              <p className='fw-normal-headline py-4'>At 4DigiTech, we are firm believers in the use of technology to boost the business. We are a group of committed professional youths who are passionate about guiding our clients through the constantly changing information technology world.</p>
              <div className='scrolldown-rel mt-5'>
                <KeyboardDoubleArrowDownIcon fontSize='large'></KeyboardDoubleArrowDownIcon>
              </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="img-container">
              <div className="row">
                <div className="col-4 mt-8">
                  <div className='oval-card'>
                    <img src={sujal} alt="team"/>
                    <span>Sujal Shrestha, CEO</span>
                  </div>
                  <div className='oval-card'>
                  <img src={abhishek} alt="team"/>
                    <span>Abhishek Dhital, Project Manager</span>
                  </div>
                  <div className='oval-card'>
                  <img src={raman} alt="team"/>
                    <span>Jenish Shrestha, IT Director</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className='oval-card'>
                  <img src={sarthak} alt="team"/>
                    <span>Sarthak Upreti, CFO</span>
                  </div>
                  <div className='oval-card'>
                  <img src={regan} alt="team"/>
                    <span>Regan Thakuri, Developer</span>
                  </div>
                  <div className='oval-card'>
                  <img src={raman} alt="team"/>
                    <span>Raman Sigdel, </span>
                  </div>
                </div>
                <div className="col-4 mt-10">
                  <div className='oval-card'>
                  <img src={jenish} alt="team"/>
                    <span>Jenish Shrestha, IT Director</span>
                  </div>
                  <div className='oval-card'>
                  <img src={kushal} alt="team"/>
                    <span>Kushal Shrestha, UI/UX Designer</span>
                  </div>
                  <div className='oval-card'>
                  <img src={dipu} alt="team"/>
                    <span>Dipu Khatri, Advisor</span>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <HowWeWork></HowWeWork>
    <ContactUs></ContactUs>
    <Subscribe></Subscribe>
    <Footer></Footer>
    </>
  );
};

export default About;