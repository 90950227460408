import office from '../../img/Office.jpg'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import art1 from '../../img/artboard1.png';

const Hero = () => {
    return (
      <div className='hr'>
        <span></span>
        <img className='heroimg'  src={office} alt="4digitech office" />
        <img src={art1} alt="art1" width={"100px"} className="artboard-png aone mover-right"/>

        <div className='container'>
          {/* Enlarge On scroll */}
          <div className='row p-0 m-0'>
              <div className='col-md-8 col-sm-12'>
                <div className='herotext'>
                  <h1 className='hero-heading'>Your Partner in Digital Transformation.</h1>
                  <h2 className='hero-tagline py-4'>Partnering with you to drive innovation, efficiency and success in your digital transformation journey, by leveraging the latest technologies, best practices and industry expertise.</h2>
                </div>
                <a className="btn-right-logo" href="/#contact">
                    Contact Us
                    <div className="arrow-wrapper">
                        <div className="arrow"></div>
                    </div>
                </a>
              </div>
              <div className='col-md-4 col-sm-12'>
              </div>
          </div>
        </div>
        <div className='scrolldown'>
          <KeyboardDoubleArrowDownIcon fontSize='large'></KeyboardDoubleArrowDownIcon>
        </div>
      </div>
    );
  };
  
  export default Hero;
  