import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { store } from "../../service/news_letter_service";

const Subscribe = () => {

    return (
      <>
        <div className="container p-4">
          <h2 className='text-center p-5 primary'>Subscribe our Newsletter and be up to date.</h2>
            <div className="box-subscribe mb-5 pb-3">
                <p className='text-center text-light m-0 p-3'>Subscribe and never miss a news.</p>
                <div className="row p-4 m-0">
                    <div className="col-md-9 col-sm-12 px-3 my-3">
                        <input type='email' className="sub-tf" placeholder="Enter Your Email."></input>
                    </div>
                    <div className="col-md-3 col-sm-12 px-3 my-3">
                        {/* <button className="btn sub-btn">Subscribe</button> */}
                        <button className="btn-fly">
                          <div className="svg-wrapper-1">
                            <div className="svg-wrapper">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                              </svg>
                            </div>
                          </div>
                          <span>Send</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
      </>
    );

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const emailRef = useRef(null);

  const handleSubmit = () => {
    setLoading(true);
    if (email === "") {
      toast.error("Email cannot be empty", { position: "top-center" });
      emailRef.current.focus();
      setLoading(false);
      return;
    }

    store({ email: email })
      .then((res) => {
        const { success, message } = res.data;

        console.log(res.data);

        setLoading(false);

        if (success) {
          toast.success("News letter sent to your email", {
            position: "top-center",
          });
          setEmail("");
        }
      })
      .catch((err) => {
        console.log("err", err.response.data);

        setLoading(false);

        const { email } = err.response.data.errors;

        if (email) {
          toast.error(email[0], { position: "top-center" });
          return;
        }
      });
  };

  return (
    <>
      <div className="container p-4">
        <h2 className="text-center p-5 primary">
          Subscribe our Newsletter and be up to date.
        </h2>
        <div className="box-subscribe mb-5 pb-3">
          <p className="text-center text-light m-0 p-3">
            Subscribe and never miss a news.
          </p>
          <div className="row p-4 m-0">
            <div className="col-md-9 col-sm-12 px-3 my-3">
              <input
                type="email"
                className="sub-tf"
                placeholder="Enter Your Email."
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div className="col-md-3 col-sm-12 px-3 my-3">
              {/* <button className="btn sub-btn">Subscribe</button> */}
              <button
                className="btn-fly"
                onClick={handleSubmit}
                disabled={loading}
              >
                <div class="svg-wrapper-1">
                  <div class="svg-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="currentColor"
                        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <span>Send</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
