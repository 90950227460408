import {Link} from 'react-router-dom';
import steps from '../../img/steps.png';
const Career = () => {
    return (
      <div id='carrer'>
        <div className='container p-5 my-6'>
          <div className='row p-0 m-0'>
            <div className='col-lg-6 p-0 d-flex'>
                <div className='img-right mover-right'>
                  <img src={steps} alt="steps"/>
                </div>
            </div>
            <div className='col-lg-6 p-0'>
                <div className='banner-text'>
                  <h3 className='primary'>Career</h3>
                  <p className='headline'>Join our creative team of artists and developers contributing to a better world. </p>
                  <p className='fw-normal-headline py-4'>At 4DigiTech, we are firm believers in the use of technology to boost the business. We are a group of committed professional youths who are passionate about guiding our clients through the constantly changing information technology world.</p>
                  <Link to='/career' className='btn btn-outline p-2'>View Openings</Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Career;
  