import About from "../components/About";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer/index";
import Hero from "../components/Hero";
import HowWeWork from "../components/HowWeWork";
import Navbar from "../components/Navbar";
import Portfolio from "../components/Portfolio/index";
import Services from "../components/Services";
import Subscribe from "../components/Subscribe";
import TechnologyUsed from "../components/TechnologyUsed";
import Testinomial from "../components/Testimonial";
import TrustedBy from "../components/TrustedBy";
import Career from "../components/Career";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <HowWeWork />
      <Services />
      <Portfolio />
      <TrustedBy />
      <Career />
      <TechnologyUsed />
      <Testinomial />
      <ContactUs />
      <Subscribe />
      <Footer />
    </>
  );
};

export default Home;
