import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "../../pages";
import About from "../../pages/about";
import Services from "../../components/Services";
import Career from "../../pages/career";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../../components/common/ScrollToTop";

const Container = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<Career />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default Container;
