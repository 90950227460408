import { Link } from "react-router-dom";
import pone from '../../img/pattern-one.png';
import ptwo from '../../img/pattern-two.png';
import office from '../../img/Office.jpg';
const Portfolio = () => {

    return (
      <div id="portfolio">
        <div className="container p-5 my-6">
          <h2 className="text-center pb-4 primary">Our Works</h2>
          <p className="text-center">Our Expertly crafted projects for a better tomorrow</p>
          <div className="row p-0 my-5">
          <img src={pone} alt="planning" width={"100px"} className="pattern-png pone mover-right"/>
          <img src={ptwo} alt="planning" width={"100px"} className="pattern-png ptwo mover-right"/>

            <div className="col-lg-3 col-md-6 col-sm-12 p-0 fd-column">
            <div className="card-bg p-4 m-2">
              <img src={office} alt="planning" width={"100px"} className="p-img"/>
              <h5 className="text-center pt-3">Smart Cleaning Solution</h5>
              <p className="fw-normal">We create and design websites that are visually appealing and easy to navigate for users.</p>
              <Link className="btn btn-outline" to='/services'>Visit Site</Link>
            </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 f p-0 fd-column">
              <div className="card-bg p-4 m-2">
                <img src={office} alt="planning" width={"100px"} className="p-img"/>
                <h5 className="text-center pt-3">News Portal</h5>
                <p className="fw-normal">We create and develop functional and dynamic websites that meets user requirement.</p>
                <Link className="btn btn-outline" to='/services'>Visit Site</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 p-0 fd-column">
            <div className="card-bg p-4 m-2">
              <img src={office} alt="planning" width={"100px"} className="p-img"/>
              <h5 className="text-center pt-3">App Development</h5>
              <p className="fw-normal">We also create and develop mobile application that is portable, flexible and scalable.</p>
              <Link className="btn btn-outline" to='/services'>Visit Site</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 p-0 fd-column">
            <div className="card-bg p-4 m-2">
              <img src={office} alt="planning" width={"100px"} className="p-img"/>
              <h5 className="text-center pt-3">Social Media Marketing</h5>
              <p className="fw-normal">We Provide Social Media Marketing plans to promote your product, service or brand.</p>
              <Link className="btn btn-outline" to='/services'>Visit Site</Link>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Portfolio;
  