import Navbar from "../components/Navbar";
import Opennings from "../components/Opennings";
import Footer from "../components/Footer/index";
import Subscribe from "../components/Subscribe";
import ContactUs from "../components/ContactUs";
import steps from "../img/steps.png";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const Career = () => {
  return (
    <>
      <Navbar />
      <div className="about">
        <div className="container">
          <div className="row pt-7 h-700">
            <div className="ol-fade-bottom"></div>
            <div className="col-md-6 col-sm-12 pt-5">
              <h1 className="primary">About Us</h1>
              <p className="headline">
                A small creative team of artists and developers contributing to
                a better world.{" "}
              </p>
              <p className="fw-normal-headline py-4">
                At 4DigiTech, we are firm believers in the use of technology to
                boost the business. We are a group of committed professional
                youths who are passionate about guiding our clients through the
                constantly changing information technology world.
              </p>
              <div className="scrolldown-rel mt-5">
                <KeyboardDoubleArrowDownIcon fontSize="large"></KeyboardDoubleArrowDownIcon>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="img-right mover-right">
                <img src={steps} alt="steps" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUs />
      <Subscribe />
      <Footer />
    </>
  );
};

export default Career;
