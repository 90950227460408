// import planning from '../../img/planning.png';
const TechnologyUsed = () => {

    return (
      <>
        <div className="container p-5">
          <h2 className='text-center p-5 primary'>Our Expertise</h2>
          <div className="pb-5">
            <div className="row pb-5">
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg"} alt="planning"/>
              </div>
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/2560px-Node.js_logo.svg.png"} alt="planning"/>
              </div>
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://1000logos.net/wp-content/uploads/2020/08/MySQL-Logo.png"} alt="planning"/>
              </div>
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/985px-Laravel.svg.png"} alt="planning"/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png"} alt="planning"/>
              </div>
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Google-flutter-logo.svg/1200px-Google-flutter-logo.svg.png"} alt="planning"/>
              </div>
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/JQuery-Logo.svg/2560px-JQuery-Logo.svg.png"} alt="planning"/>
              </div>
              <div className="col-md-3 col-sm-6 col-6 tlogobg">
                <img className="tlogo" src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/PHP_Logo%2C_text_only.svg/800px-PHP_Logo%2C_text_only.svg.png"} alt="planning"/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default TechnologyUsed;
  